import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses, clsx } from '../../styles';
import { MenuList } from '@mui/material';
import DrawerMenuNavItem from '../DrawerMenuNavItem';
import { useAppData } from '../../context/app-data';

function renderNavItems({ menuItems = [], page = {}, ...params }) {
	const { classes, className } = params.props;

	return (
		<MenuList key={page.id} className={clsx(classes.root, className)}>
			{menuItems.reduce(
				(items, menu, key) => reduceChildRoutes({ items, menu, key, page, ...params }), []
			)}
		</MenuList>
	);
}

function reduceChildRoutes({ props, pathname, items, menu, depth, key, pages, page, hasIcon }) {
	const {icons, DrawerMenuNavItemProps} = props;
	const {root, ...classes} = props.classes;

	let openImmediately = false;

	if (!pages?.[menu?.id]) {
		throw new Error(`Page: ${menu?.id} not found!`);
	}

	if (menu.hidden) return items;

	const parents = (page.menu && page.menu.parents) || [];

	key = `${menu.id}_${key}`;

	const icon = depth === 0 && icons?.[menu.id] || null;
	hasIcon = hasIcon && depth > 0 || Boolean(icon);
	if (depth === 0 && !icon) {
		hasIcon = false;
	}

	if (!menu.label && !menu.hideSubmenu && menu.children && menu.children.length > 1) {
		const title = menu.title || pages[menu.id].title;
		const childrenPaths = menu.children.map(item => {
			if (!pages[item.id]) {
				throw new Error(`Page "${item.id}" missing!`);
			}
			return pages[item.id].asPath || pages[item.id].pathname;
		});

		if (Array.isArray(parents)) {
			parents.forEach(parentId => {
				if (parentId === menu.id) {
					openImmediately = true;
				}
			});
		}

		if (!openImmediately) {
			openImmediately = childrenPaths.includes(pathname);
		}

		items.push(
			<DrawerMenuNavItem
				classes={classes}
				depth={depth}
				key={key}
				openImmediately={openImmediately}
				title={title}
				page={page}
				pathname={pathname}
				menu={menu}
				icon={icon}
				hasIcon={hasIcon}
				{...DrawerMenuNavItemProps}
			>
				{renderNavItems({
					props,
					page,
					pages,
					menuItems: menu.children,
					pathname,
					depth: depth + 1,
					hasIcon
				})}
			</DrawerMenuNavItem>
		);
	} else {
		menu = menu.children && menu.children.length === 1 && !menu.hideSubmenu ? menu.children[0] : menu;
		page = page.id === menu.id ? page : pages[menu.id];
		const title = menu.title || page.title;

		if (menu.pathname || page.pathname || menu.label) {
			items.push(
				<DrawerMenuNavItem
					classes={classes}
					depth={depth}
					key={key}
					title={title}
					page={page}
					pathname={pathname}
					menu={menu}
					icon={icon}
					hasIcon={hasIcon}
					to={menu.pathname || page.pathname}
					onClick={props.onClick}
					{...DrawerMenuNavItemProps}
				/>
			);
		}
	}

	return items;
}

const useClasses = createClasses((theme) => ({
	root: {
		outline: 'none'
	}
}), {
	name: 'RaDrawerMenu'
});

function DrawerMenu(props) {
	const classes = useClasses(props);
	const { pages, menu = [] } = useAppData();

	const {
		pathname,
		menuItems = menu,
		pageId,
		...rest
	} = props;

	return renderNavItems({
		props: {
			...rest,
			classes
		},
		page: pages[pageId] || {},
		pages,
		pathname,
		menuItems,
		depth: 0
	});
}

DrawerMenu.propTypes = {
	classes: PropTypes.object,
	pathname: PropTypes.string.isRequired,
	pageId: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	DrawerMenuNavItemProps: PropTypes.object
};

export default React.memo(DrawerMenu);
