import { useTheme } from 'styles';
import Stack from '@mui/material/Stack';

export default function AppFooterContainer(props) {
	const { children, ...rest } = props;
	const theme = useTheme();

	return (
		<Stack
			alignItems={{xs: 'center', desktop: 'stretch'}}
			direction={{xs: 'column', desktop: 'row'}}
			maxWidth={theme.config.containerMaxWidth}
			justifyContent="space-between"
			backgroundColor="background.paper"
			paddingX={theme.config.containerSpacing}
			paddingY={3}
			borderRadius={{
				lg: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`
			}}
			{...rest}
		>
			{children}
		</Stack>
	);
}
