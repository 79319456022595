import { useTheme } from 'styles';
import { useLocation } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from 'components/Button';
import { appData } from 'config/data.cjs';

const ButtonProps = {
	size: 'small',
	sx: {minWidth: 'auto'}
};

const { pages } = appData;

export default function AppFooterBottom(props) {
	const {
		children,
		disableContactBtn,
		disableImprintBtn,
		disableTermsOfBusiness,
		disablePrivacyPolicy,
		...rest
	} = props;

	const theme = useTheme();
	const location = useLocation();
	const { pathname } = location;

	return (
		<Stack
			alignItems="center"
			direction={{xs: 'column', md: 'row'}}
			maxWidth={theme.config.containerMaxWidth}
			backgroundColor={theme.vars.palette.background.overlay}
			gap={{xs: 1, md: 0}}
			paddingX={theme.config.containerSpacing}
			paddingY={1.5}
			{...rest}
		>
			<Typography
				width={{xs: 'auto', md: '30%'}}
				component="div"
				color="text.secondary"
				fontSize="inherit"
				order={{xs: 3, md: 0}}
			>
				{`© ${new Date().getFullYear()} ${appData.title}`}
			</Typography>

			<Stack direction="row" width={{xs: 'auto', md: '40%'}} justifyContent="center" order={{xs: 1, md: 2}}>
				{!disableContactBtn && (
					<Button
						{...ButtonProps}
						to={appData.pages.contact.pathname}
						color={pathname === pages.contact.pathname ? 'primary' : 'inherit'}
					>
						{appData.pages.contact.title}
					</Button>
				)}
				{!disableImprintBtn && (
					<Button
						{...ButtonProps}
						to={appData.pages.imprint.pathname}
						color={pathname === pages.imprint.pathname ? 'primary' : 'inherit'}
					>
						{appData.pages.imprint.title}
					</Button>
				)}
				{!disableTermsOfBusiness && (
					<Button
						{...ButtonProps}
						to={appData.pages.termsOfBusiness.pathname}
						color={pathname === pages.termsOfBusiness.pathname ? 'primary' : 'inherit'}
					>
						{appData.pages.termsOfBusiness.title}
					</Button>
				)}
				{!disablePrivacyPolicy && (
					<Button
						{...ButtonProps}
						to={appData.pages.privacyPolicy.pathname}
						color={pathname === pages.privacyPolicy.pathname ? 'primary' : 'inherit'}
					>
						{appData.pages.privacyPolicy.title}
					</Button>
				)}
			</Stack>
			<Stack
				direction="row"
				display="inline-flex"
				flexWrap="wrap"
				width={{xs: 'auto', md: '30%'}}
				justifyContent={{xs: 'center', md: 'flex-end'}}
				order={{xs: 2, md: 3}}
				rowGap={1}
				columnGap={2}
				marginBottom={{xs: 1, md: 0}}
			>
				{children}
			</Stack>
		</Stack>
	);
}
