import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses, clsx } from '../../styles';
import { ListItem } from '@mui/material';
import { ListSubheader } from '@mui/material';
import { Collapse } from '@mui/material';
import { Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Button from '../Button';
import NavLink from '../NavLink';
import { useMemoCallback } from '../../hooks';

const defaultProps = {
	dense: true,
	openImmediately: false
};

const useClasses = createClasses((theme, {dense = defaultProps.dense}) => {
	const { darkMode } = theme.config;

	return {
		root: {},
		item: {
			'& ul > .MuiDivider-root': {
				marginLeft: theme.spacing(2),
				marginRight: theme.spacing(2)
			},
			'& > .MuiButton-root > .MuiSvgIcon-root': {
				transition: theme.transitions.create('transform', {
					easing: theme.transitions.easing.easeInOut,
					duration: theme.transitions.duration.comlex
				})
			}
		},
		itemLeaf: {
			display: 'block',
			outline: 'none',
			paddingTop: 0,
			paddingBottom: 0
		},
		itemActive: {
			'& > .MuiButton-root': {
				color: theme.vars.palette.text.primary,
				backgroundColor: theme.helpers.alpha('text-primary', .06),
				'&:hover': {
					backgroundColor: theme.helpers.alpha('text-primary', .1)
				}
			}
		},
		button: {
			textAlign: 'left',
			justifyContent: 'flex-start',
			textTransform: 'none',
			width: '100%',
			padding: theme.spacing(1.5),
			borderRadius: 0,
			fontSize: theme.typography.pxToRem(16),
			color: theme.vars.palette.text.secondary,
			minHeight: theme.spacing(6),
			'&:hover': {
				backgroundColor: theme.helpers.alpha('text-primary', .06)
			},
			transition: theme.transitions.create(['background-color', 'box-shadow', 'border', 'color'], {
				duration: theme.transitions.duration.short
			}),
			...(dense && {
				fontSize: theme.typography.pxToRem(14),
				minHeight: theme.spacing(4.5),
				paddingTop: theme.spacing(1),
				paddingBottom: theme.spacing(1)
			}),
			'& > .MuiButton-endIcon': {
				marginLeft: 'auto',
				paddingLeft: theme.spacing(.5)
			}
		},
		buttonLeaf: {},
		buttonLeafTitle: {
			flex: 1
		},
		buttonLeafLabel: {
			width: '100%',
			display: 'flex'
		},
		buttonLeafSubheader: {},
		active: {
			color: darkMode ? theme.vars.palette.grey[200] : theme.vars.palette.primary[800],
			backgroundColor: darkMode ? theme.vars.palette.grey[700] : theme.vars.palette.primary[50],
			'&:hover': {
				backgroundColor: darkMode ? theme.vars.palette.grey[600] : theme.vars.palette.primary[100]
			}
		},
		dense: {},
		divider: {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1)
		},
		subheader: {
			color: theme.helpers.alpha('text-primary', .4),
			fontWeight: 500,
			fontSize: theme.typography.pxToRem(dense ? 12 : 14),
			textTransform: 'uppercase',
			'&:not(:first-of-type)': {
				marginTop: theme.spacing(1)
			}
		},
		itemLeafSubheader: {
			width: '100%',
			display: 'flex',
			lineHeight: theme.spacing(5.5),
			...(dense && {
				lineHeight: theme.spacing(4.5)
			})
		},
		icon: {
			'&&': {
				fontSize: '1.25rem'
			}
		},
		iconUp: {
			transform: 'rotate(-180deg)'
		},
		iconRight: {
			transform: 'rotate(-90deg)'
		},
		iconExternal: {
			...(dense && {
				'&&': {
					fontSize: theme.typography.pxToRem(14),
					marginRight: theme.spacing(.125)
				}
			})
		},
		listItemIcon: {
			display: 'inline-flex',
			minWidth: theme.spacing(4),
			flexShrink: 0,
			color: 'inherit'
		},
		menu: {}
	};
}, {
	name: 'RaDrawerMenuNavItem',
	mergeClassName: false
});

function DrawerMenuNavItem(props) {
	const {
		classes: classesProp,
		className,
		children,
		dense = defaultProps.dense,
		depth,
		page,
		pathname,
		menu,
		to,
		onClick,
		openImmediately = defaultProps.openImmediately,
		ListItemClasses,
		ButtonProps,
		ButtonComponent = Button,
		MenuButtonProps,
		MenuButtonComponent = props.ButtonComponent || Button,
		NavLinkComponent = NavLink,
		ButtonClasses: ButtonClassesProp,
		title,
		icon,
		hasIcon,
		chevronRightIcon,
		spacingValue = 8,
		...rest
	} = props;

	const classes = useClasses(props);
	const [open, setOpen] = React.useState(openImmediately);

	const handleMenuToggle = useMemoCallback(() => {
		setOpen(!open);
	});

	const handleClick = useMemoCallback((event) => {
		if (typeof onClick === 'function') {
			onClick();
		}
	});

	const ButtonClasses = React.useMemo(() => ({
		label: classes.buttonLeafLabel,
		...ButtonClassesProp
	}), [classes.buttonLeafLabel, ButtonClassesProp]);

	const style = React.useMemo(() => ({
		...(spacingValue && {
			paddingLeft: hasIcon && depth > 0 ? spacingValue * (3 + (depth === 1 ? 3 : 2.5) * depth) : spacingValue * (2 + 2 * depth)
		})
	}), [depth, hasIcon, spacingValue]);

	const iconNode = icon ? (
		<span className={classes.listItemIcon}>
			{icon}
		</span>
	) : null;

	const buttonMenuMode = Boolean(props.MenuButtonComponent);

	if (to || menu.label) {
		const extern = to && to.startsWith('http');
		const isActive = to === pathname || page.isActive;

		return (
			<>
				{menu.dividerBefore && (
					<Divider className={classes.divider} />
				)}
				<ListItem
					className={clsx(
						classes.root,
						classes.itemLeaf,
						dense && classes.dense,
						menu.uniqueClasssName && `RaDrawerMenuNavItem-${menu.id}`,
						className
					)}
					disableGutters
					data-active={!extern && isActive ? true : null}
					classes={ListItemClasses}
					{...rest}
				>
					{menu.subheader && (!page.pathname ||  menu.pathname === false) ? (
						<ListSubheader
							style={style}
							component="div"
							className={clsx(
								classes.subheader,
								classes.itemLeafSubheader
							)}
						>
							{menu.title || title}
						</ListSubheader>
					) : (
						<ButtonComponent
							component={NavLinkComponent}
							{...ButtonProps}
							style={style}
							className={clsx(
								classes.button,
								classes.buttonLeaf,
								isActive && classes.active,
								menu.subheader && classes.subheader,
								menu.label && classes.buttonLeafSubheader
							)}
							classes={ButtonClasses}
							onClick={!extern ? handleClick : null}
							to={!extern ? to : null}
							href={extern ? to : null}
							target={extern ? '_blank' : null}
							rel={extern ? 'noopener' : null}
							as={page.asPath}
							{...(!extern ? { end: !menu.hideSubmenu } : null)}
							{...(chevronRightIcon && !extern && {
								endIcon: <ExpandMoreIcon className={(classes.icon, classes.iconRight)}/>
							})}
							{...(extern && {
								endIcon: <OpenInNewIcon fontSize="small" className={classes.iconExternal}/>
							})}
						>
							{iconNode}
							{menu.title || title}
						</ButtonComponent>
					)}
				</ListItem>
				{menu.dividerAfter && (
					<Divider className={classes.divider} />
				)}
			</>
		);
	}

	return (
		<>
			{menu.dividerBefore && (
				<Divider className={classes.divider} />
			)}
			<ListItem
				className={clsx(
					classes.root,
					classes.item,
					classes.itemLeaf,
					dense && classes.dense,
					openImmediately && classes.itemActive,
					menu.uniqueClasssName && `RaDrawerMenuNavItem-${menu.id}`,
					className
				)}
				classes={ListItemClasses}
				disableGutters
				{...rest}
			>
				<MenuButtonComponent
					{...ButtonProps}
					{...MenuButtonProps}
					{...(buttonMenuMode && {
						Icon: ExpandMoreIcon,
						IconProps: {
							className: classes.icon
						},
						MenuProps: {
							className: classes.menu
						},
						menuItems: React.Children.map(children, element => {
							if (React.isValidElement(element) && element.props.children) {
								return element.props.children;
							}
						}).filter(Boolean)
					})}
					className={classes.button}
					classes={ButtonClasses}
					onClick={handleMenuToggle}
					style={style}
					{...(!menu.label && {
						endIcon: <ExpandMoreIcon className={clsx(classes.icon, open && classes.iconUp)}/>
					})}
				>
					{iconNode}
					{title}
				</MenuButtonComponent>
				{!buttonMenuMode && (
					<Collapse in={open} timeout="auto" unmountOnExit>
						{children}
					</Collapse>
				)}
			</ListItem>
			{menu.dividerAfter && (
				<Divider className={classes.divider} />
			)}
		</>
	);
}

DrawerMenuNavItem.propTypes = {
	classes: PropTypes.object,
	children: PropTypes.node,
	ListItemClasses: PropTypes.object,
	ButtonClasses: PropTypes.object,
	ButtonProps: PropTypes.object,
	ButtonComponent: PropTypes.elementType,
	MenuButtonProps: PropTypes.object,
	MenuButtonComponent: PropTypes.elementType,
	depth: PropTypes.number.isRequired,
	page: PropTypes.object.isRequired,
	pathname: PropTypes.string.isRequired,
	menu: PropTypes.object.isRequired,
	to: PropTypes.string,
	onClick: PropTypes.func,
	openImmediately: PropTypes.bool,
	chevronRightIcon: PropTypes.bool,
	dense: PropTypes.bool,
	title: PropTypes.string.isRequired,
	icon: PropTypes.node,
	spacingValue: PropTypes.number
};

export default React.memo(DrawerMenuNavItem);
