import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses, clsx } from '../../styles';
import { Container } from '@mui/material';
import AppBar from '../AppBar';
import AppDrawer from '../Drawer';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

const useClasses = createClasses((theme, props) => {
	const {appBar: appBarTrasition} = theme.config.transitions || {};

	const { open, clippedDrawer, AppDrawerProps } = props;
	const { variant = 'temporary', drawerWidth = 300 } = AppDrawerProps || {};

	const permanent = variant === 'permanent';
	const persistent = variant === 'persistent';
	const temporary = variant === 'temporary';

	const shiftedProps = {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: `${drawerWidth}px`
	};

	const transitions = {
		appBar: appBarTrasition,
		shiftIn: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		shiftOut: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen
		})
	};

	return {
		root: {
			position: 'relative',
			display: 'flex',
			width: '100%',
			minHeight: '100vh',
			flexDirection: 'column',
			WebkitBackfaceVisibility: 'hidden' // Fix Flickering
		},
		drawer: {
			...(clippedDrawer && {
				'& > .MuiDrawer-paper': {
					top: 'var(--app-bar-height)',
					maxHeight: 'calc(100% - var(--app-bar-height))',
					'& > nav': {
						paddingTop: theme.spacing(1)
					}
				}
			})
		},
		appBar: {
			transition: transitions.appBar,
			...(clippedDrawer ? {
				zIndex: theme.zIndex.drawer + 1
			} : null)
		},
		content: {
			position: 'relative',
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			margin: 0
		},
		extended: {},
		appBarShift: {
			...(permanent && !clippedDrawer && {
				...shiftedProps
			}),
			...(persistent && {
				transition: transitions.appBar
					? [transitions.appBar, transitions.shiftIn].join(',')
					: transitions.shiftIn,
				...(open && {
					...(!clippedDrawer && {
						...shiftedProps
					}),
					transition: transitions.appBar
						? [transitions.appBar, transitions.shiftOut].join(',')
						: transitions.shiftOut
				})
			})
		},
		contentShift: {
			...(permanent && {
				...shiftedProps
			}),
			...(persistent && {
				transition: transitions.shiftIn,
				...(open && {
					...shiftedProps,
					transition: transitions.shiftOut
				})
			})
		},
		shifted: {},
		permanent: {},
		persistent: {},
		temporary: {},
		clipped: {}
	};
}, {
	name: 'RaAppFrame',
	mergeClassName: false
});

function AppFrame(props) {
	const {
		classes: classesProp,
		className,
		children,
		open,
		onClose,
		onToggle,
		clippedDrawer,
		AppBarComponent = AppBar,
		AppBarProps = {},
		AppDrawerProps = {},
		ContainerProps = {},
		...rest
	} = props;

	const classes = useClasses(props);

	const {
		classes: AppBarClasses,
		className: AppBarClassName,
		MenuIconComponent,
		extendedToolbarContent
	} = AppBarProps || {};

	const {
		classes: AppDrawerClasses,
		className: AppDrawerClassName,
		variant: drawerVariant = 'temporary',
		drawerWidth = 300
	} = AppDrawerProps || {};

	const hasDrawer = Boolean(AppDrawerProps?.children);
	const permanentDrawer = drawerVariant === 'permanent';
	const persistentDrawer = drawerVariant === 'persistent';
	const temporaryDrawer = drawerVariant === 'temporary';

	return (
		<div
			{...rest}
			className={clsx(
				classes.root,
				extendedToolbarContent && classes.extended,
				className
			)}
		>
			<AppBarComponent
				{...AppBarProps}
				MenuIconComponent={hasDrawer ? MenuIconComponent || (open ? MenuOpenIcon : undefined) : undefined}
				onToggle={hasDrawer ? permanentDrawer ? null : onToggle : null}
				classes={AppBarClasses}
				className={clsx(
					classes.appBar,
					hasDrawer && classes[drawerVariant],
					hasDrawer && clippedDrawer && classes.clipped,
					AppBarClasses && AppBarClasses.root,
					AppBarClassName,
					!temporaryDrawer && !clippedDrawer && classes.appBarShift,
					persistentDrawer && open && classes.shifted
				)}
			/>

			{hasDrawer &&
				<AppDrawer
					{...AppDrawerProps}
					variant={drawerVariant}
					drawerWidth={drawerWidth}
					open={permanentDrawer || open}
					onClose={onClose}
					classes={AppDrawerClasses}
					className={clsx(
						classes.drawer,
						hasDrawer && classes[drawerVariant],
						hasDrawer && clippedDrawer && classes.clipped,
						AppDrawerClasses && AppDrawerClasses.root,
						AppDrawerClassName
					)}
				/>
			}

			{children &&
				<Container
					maxWidth={false}
					disableGutters
					{...ContainerProps}
					className={clsx(
						classes.content,
						hasDrawer && classes[drawerVariant],
						hasDrawer && clippedDrawer && classes.clipped,
						!temporaryDrawer && classes.contentShift,
						persistentDrawer && open && classes.shifted
					)}
				>
					{children}
				</Container>
			}
		</div>
	);
}

AppFrame.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node,
	appBarContent: PropTypes.node,
	AppBarProps: PropTypes.object,
	AppDrawerProps: PropTypes.object,
	ContainerProps: PropTypes.object,
	clippedDrawer: PropTypes.bool
};

export default React.memo(AppFrame);
