import * as React from 'react';
import { useEventListener } from 'react-app/hooks';
import { useBrowser } from 'react-app/context/browser';
import { usePrevious } from 'react-app/hooks';

export default function useAppLayout() {
	const drawerRef = React.useRef();

	const browser = useBrowser();
	const { isWidthUp, breakpoint } = browser;

	const lgUp = isWidthUp('lg', breakpoint);

	const lastBrealpoint = usePrevious(breakpoint);

	const [drawerOpen, setDrawerOpen] = React.useState(lgUp ? true : false);

	function handleDrawerToggle() {
		setDrawerOpen(!drawerOpen);
	}

	function handleDrawerClose() {
		setDrawerOpen(false);
	}

	useEventListener('pageChangeEnd', () => {
		if (!lgUp) {
			const drawerNode = drawerRef.current;
			try {
				drawerNode.scrollToActiveItem(false);
			} catch (err) {
				console.error(err);
			}
		}
	});

	React.useEffect(() => {
		if (lastBrealpoint && lastBrealpoint !== breakpoint) {
			if (!lgUp && drawerOpen) {
				setDrawerOpen(false);
			}
			if (lgUp && !drawerOpen) {
				setDrawerOpen(true);
			}
		}
	}, [breakpoint, lastBrealpoint, lgUp, drawerOpen]);

	return {
		browser,
		breakpoint,
		lastBrealpoint,
		drawerOpen,
		drawerRef,
		handleDrawerToggle,
		handleDrawerClose
	};
}
