import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function HqIcon(props) {
	return (
		<SvgIcon {...props}>
			<path d="m18.67,2H2v20h20V5.33l-3.33-3.33Zm-.7,13.86l-2.87-2.88h1.53c.05-.2.07-.44.07-.69,0-1.43-.64-2.27-1.67-2.27s-1.65.84-1.65,2.23.54,2.18,1.45,2.29c.06.02.12.02.19.02h.03c.06,0,.14,0,.2-.02l1.19,1.19s-.11.06-.17.08c-.02,0-.02.02-.03.02-.02.01-.05.01-.07.03-.03.01-.04.01-.07.03-.04,0-.05,0-.08,0-.02,0-.04.02-.05.02-.05.02-.08.02-.11.03-.02,0-.03.02-.05.02-.03,0-.08.01-.11.01-.02.02-.03.02-.05.02-.03,0-.06.02-.09.02h-.07s-.08.01-.11.01h-.62c-.06,0-.11-.01-.17-.01h-.02c-.73-.08-1.43-.37-1.97-.83-.44-.36-.76-.84-.95-1.41v2.11h-1.89v-2.87h-2.52v2.85h-1.91v-7.14h1.91v2.71h2.52v-2.71h1.89v2.08c.21-.56.52-1.03.95-1.41.64-.54,1.51-.84,2.45-.84,2.16,0,3.6,1.49,3.6,3.71,0,.67-.09,1.21-.35,1.79l1.81,1.8h-2.14Z"/>
		</SvgIcon>
	);
}
