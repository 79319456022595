import * as React from 'react';
import { useApp } from 'react-app/context/app';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';

export function useThemeSwitch() {
	const [ app, appActions ] = useApp();
	const { colorMode } = app;
	const { setColorMode } = appActions;
	const darkMode = colorMode === 'dark';

	function themeSwicth() {
		window.requestAnimationFrame(() => {
			const mode = darkMode ? 'light' : 'dark';
			setColorMode(mode);
		});
	}

	return {
		colorMode,
		darkMode,
		themeSwicth
	};
}

function ThemeSwitch(props) {
	const { darkMode, themeSwicth } = useThemeSwitch();
	const { IconProps, variant = 'button', ...rest } = props;

	return (
		variant === 'button' ? (
			<Button
				color="inherit"
				{...props}
				onClick={themeSwicth}
				startIcon={darkMode ? (
					<LightModeIcon/>
				) : (
					<DarkModeIcon/>
				)}
			>
				{`${darkMode ? 'Helles' : 'Dunkles'} Farbschema`}
			</Button>
		) : (
			<IconButton
				title={`${darkMode ? 'Helles' : 'Dunkles'} Farbschema`}
				color="inherit"
				{...rest}
				onClick={themeSwicth}
			>
				{darkMode ? (
					<LightModeIcon  {...IconProps}/>
				) : (
					<DarkModeIcon  {...IconProps}/>
				)}
			</IconButton>
		)
	);
}

export default React.memo(ThemeSwitch);
