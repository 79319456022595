import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses } from '../../styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import { Menu } from '@mui/material';
import { ListSubheader } from '@mui/material';
import { Divider } from '@mui/material';
import { IconButton } from '@mui/material';
import { useMemoCallback } from '../../hooks';
import Button from '../Button';

const useClasses = createClasses((theme, {menuTitle}) => ({
	root: {},
	divider: {
		marginBottom: theme.spacing(1)
	},
	listSubheader: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: theme.spacing(-1),
		lineHeight: 1.5,
		minHeight: theme.spacing(6),
		alignItems: 'center',
		'& > span': {
			display: 'inline-flex',
			margin: 'auto 0'
		}
	},
	listSubheaderIcon: {
		alignSelf: 'center',
		marginLeft: menuTitle ? theme.spacing(2) : 'auto',
		marginRight: theme.spacing(-1)
	}
}), {
	name: 'MenuButton'
});

const MenuButton = React.forwardRef(function MenuButton(props, ref) {
	const {
		classes: classesProp,
		className,
		children,
		menuTitle,
		showMenuHeader,
		MenuProps,
		MenuItemtProps,
		ButtonCompoent = Button,
		Icon = ArrowDropDownIcon,
		IconProps,
		menuItems = [],
		onOpen,
		onClose,
		...rest
	} = props;

	const classes = useClasses(props);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const actionRef = React.useRef();
	const rippleRef = React.useRef();

	const handleMenuOpen = useMemoCallback((event) => {
		actionRef.current.focusVisible();
		rippleRef.current.start(event);
		setAnchorEl(event.currentTarget);
		if (typeof onOpen === 'function') {
			onOpen(event);
		}
	});

	const handleMenuClose = useMemoCallback((event) => {
		rippleRef.current.stop(event);
		setAnchorEl(null);
		if (typeof onClose === 'function') {
			onClose(event);
		}
	});

	React.useImperativeHandle(ref, () => ({
		open: handleMenuOpen,
		close: handleMenuClose,
		isOpen: open
	}));

	return (
		<>
			<ButtonCompoent
				ref={ref}
				action={actionRef}
				touchRippleRef={rippleRef}
				disableTouchRipple={open}
				{...rest}
				endIcon={<Icon {...IconProps} sx={{'&&': {mx: -.5, ...(open && {transform: 'rotate(-180deg)'})}}}/>}
				onClick={handleMenuOpen}
				className={classes.root}
			>
				{children}
			</ButtonCompoent>
			<Menu
				disableAutoFocusItem
				{...MenuProps}
				onClose={handleMenuClose}
				open={open}
				anchorEl={anchorEl}
			>
				{showMenuHeader && (
					<>
						<ListSubheader className={classes.listSubheader}>
							{menuTitle}
							<IconButton
								size="small"
								onClick={handleMenuClose}
								className={classes.listSubheaderIcon}
							>
								<CloseIcon fontSize="small"/>
							</IconButton>
						</ListSubheader>
						<Divider className={classes.divider} component="li"/>
					</>
				)}
				{menuItems}
			</Menu>
		</>
	);
});

MenuButton.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node,
	menuTitle: PropTypes.string,
	showMenuHeader: PropTypes.bool,
	MenuProps: PropTypes.object,
	MenuItemtProps: PropTypes.object,
	menuItems: PropTypes.oneOfType([PropTypes.node.isRequired, PropTypes.array.isRequired]),
	onOpen: PropTypes.func,
	onClose: PropTypes.func,
	ButtonCompoent: PropTypes.elementType
};

export default React.memo(MenuButton);
